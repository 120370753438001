.diwali-celebration {
    background-color: #f5f5f5;
    padding: 20px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    background-image: url("https://srichants.in/wp-content/uploads/2024/11/Happy-Sankranti-1024x585.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height:100vh;
    position: fixed;
    top:0px;
    left:0px;
    right:0px;
    z-index:999999999999;
}

.heading-diwali-celebration{
    font-size: 60px;
}
.heading-diwali{
    text-shadow: 2px 2px #fff;
    font-size:60px;
    font-weight: bold;
    background: -webkit-linear-gradient(left, #f00, #0f0, #00f, #f0f, #ff0, #f0f, #0ff, #0f0, #ff0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.diwali-celebration p{
    text-shadow: 2px 0px green;
    text-transform: capitalize;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.bottom-right-image {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 200px;
    height: 200px;
    transform: rotateY(180deg);
    animation: sideLeftAnimation 0.5s ease-in-out;
}


@keyframes sideLeftAnimation {
    from{
        opacity:0;
        transform: translateX(100px);
    }
    to{
        opacity:1;
        transform: translateX(0px);
    }
}

.bottom-left-image{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 200px;
    height: 200px;
    animation: sideLeftAnimation 0.5s ease-in-out;
}

.continue-btn{
    position: absolute;
    bottom: 200px;
    right:0;
    left:0;
    margin:auto;
    width:100px;
    height:100px;
    font-size:20px;
    background: linear-gradient(to right, #f00, rgb(38, 0, 255));
    border-radius: 100px;
    border:none;
    box-shadow: 0px 0px 10px #000;
    color:white;
    font-weight: bold;
    animation: pulse 2s 1;
    transition: 2s;
    font-size:50px;
}

.continue-btn:hover{
    box-shadow: 0px 0px 100px #000;
    transition: 2s;
    transform: scale(1.5);
}

@keyframes pulse {
    from {
        box-shadow: 0px 0px 10px #000;
    }
    to {
        box-shadow: 0px 0px 100px #000;
        transform: scale(1.5);
    }
}

.vm-character-right{
    position: absolute;
    bottom:0;
    right:0;
}

.vm-character-left{
    position: absolute;
    bottom:0;
    left:0;
    transform: rotateY(180deg);
}


  .btn-wrapper::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    padding: 10px;
    background: conic-gradient(#4CAF50 0deg, transparent 90deg, #4CAF50 180deg, transparent 270deg, #4CAF50 360deg);
    z-index: 0;
    animation: rotateBorder 2s linear infinite;
    mask: linear-gradient(#000 0 0) content-box, linear-gradient(#000 0 0);
    -webkit-mask: linear-gradient(#000 0 0) content-box, linear-gradient(#000 0 0);
    mask-composite: exclude;
    -webkit-mask-composite: destination-out;
  }

  @keyframes rotateBorder {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }