.video-item{
  padding:5px 10px;
  cursor: pointer;
}
.video-item span{
  font-weight: bold;
}
.video-item:hover{
  background-color: #0d6efd;
  color:white;
  transition: 0.5s;
}
.locked-lecture:hover{
  background-color: gray;
  cursor: not-allowed;
}
.selected-video{
  background-color: #0d6efd;
  color:white;
}
.course-icon{
  width:100px;
  height:50px;
}
.video-items{
  max-height:450px;
  overflow: auto;
}

.video-items2{
  height:530px;
  overflow: auto;
}
.video-item3{
  width:100%;
  height:524px;
  overflow: hidden;
}

.pl-2{
  padding-left:15px;
}
.course-thumbnail{
  width:100px;
}
.z-11{
  z-index: 10!important;
}
@media screen and (max-width:480px) { 
  .enrollOption *{
    font-size:11px;
  }
  .img-fluid{
    width:95px;
  }
}