.video-item{
  padding:5px 20px;
  cursor: pointer;
}
.video-item span{
  font-weight: bold;
}
.video-item:hover{
  background-color: #0d6efd;
  color:white;
  transition: 0.5s;
}
.selected-video{
  background-color: #0d6efd;
  color:white;
}
.course-icon{
  width:150px;
  height:50px;
}
.video-items2{
  height:530px;
  overflow: auto;
}
.video-item3{
  width:100%;
  height:524px;
  overflow: hidden;
}

.pl-2{
  padding-left:15px;
}