.course-image {
  width: 50%;
  height: 200px;
}

.course-image2 {
  height: 250px;
}

.srv-validation-message,
.error-message {
  color: orange;
}

.new-image {
  width: 100px;
  height: 50px;
  position: relative;
  top: -20px;
  right: -153px;
}

.new-image2 {
  width: 100px;
  height: 50px;
  position: relative;
  top: -20px;
  right: -124px;
}

.new-img {
  width: 50px;
  height: 50px;
  position: relative;
  top: 0px;
  right: 0px;
}

.cursor-pointer {
  cursor: pointer;
}

.custom-bg,
.btn-primary,
.bg-primary {
  /*background: linear-gradient(to right bottom,#6d327c,#485DA6,#00a1ba,#01b18e,#32b37b);*/
  background: linear-gradient(to right, rgba(13, 53, 214, 0.849), rgb(255, 0, 140));
  border: 0 !important;
}

.custom-bg2,
.bg-dark {
  background: linear-gradient(to right, rgba(22, 22, 22, 0.849), rgb(54, 54, 88));
}

.custom-bg3 {
  background: linear-gradient(to right, rgba(22, 22, 22, 0.849), rgb(31, 31, 189));
  transition: color 5s ease-in-out;
}

.custom-bg3:hover {
  background: linear-gradient(to right, rgba(184, 13, 214, 0.507), rgba(70, 6, 230, 0.838));
}

.h250 {
  min-height: 350px;
}

.fs-50 {
  font-size: 50px !important;
}

.fs-100 {
  font-size: 100px !important;
}

.li-hover:hover {
  background-color: lightgray;
  transition: 0.5s;
}

@media screen and (max-width:480px) {
  .typing-effect{
    animation: none!important;
  overflow: none!important;
  white-space: wrap!important;
  }
  .timeframes3 {
    height: 25px !important;
  }

  .timeframes {
    min-width: 100px !important;
  }

  .timeframes-height {
    max-height: 185px !important;
    overflow: auto;
  }

  .navbar-brand h2 {
    font-size: 20px !important;
  }

  .user-dropdown1 {
    width: 100% !important;
  }

  .navOption {
    margin: 5px;
  }

  .mbw-100 {
    width: 100%;
  }

  .new-image {
    left: 43%;
    transform: rotateZ(1deg);
  }

  .embed-responsive-item {
    height: 280px !important;
  }

  .slideImage {
    width: 100% !important;
    height: 250px !important;
  }

  .showPopup h3,
  .showPopup h1,
  .showPopup h4,
  .dashboard-heading {
    font-size: 18px;
  }

  .timer-container {
    display: none;
  }

  .popup {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw !important;
  }

  .close-btn {
    right: 10px !important;
    top: 10px !important;
  }

  .sticky-board {
    position: relative !important;
    top: 0 !important;
  }

  .d-reverse {
    display: flex !important;
    flex-direction: column-reverse !important;
  }

  .main-content {
    height: 800px !important;
  }

  .video-player-overlay{
    border:0px!important;
  }

}

.festival-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 999999;
  animation: fadeOut 5s 1 3s;
}

.festival-img {
  width: 100%;
  height: 100vh;
}

@keyframes fadeOut {
  0% {
    opacity: 1
  }

  50% {
    opacity: 0.8;
    background: linear-gradient(to right, rgba(184, 13, 214, 0.507), rgba(70, 6, 230, 0.838));
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  100% {
    opacity: 1
  }
}

.shadowAnim {
  animation: fadeOut 5s infinite;
}

/* The flip box container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-box {
  /*background-color: transparent;*/
  /*width: 300px;*/
  height: 200px;
  /*border: 1px solid #f1f1f1;*/
  perspective: 1000px;
}

/* This container is needed to position the front and back side */
.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-box:hover .flip-box-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-box-front,
.flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  /* Safari */
  backface-visibility: hidden;
}

/* Style the front side */
.flip-box-front {
  /*background-color: #bbb;*/
  color: black;
}

/* Style the back side */
.flip-box-back {
  /*background-color: dodgerblue;*/
  color: white;
  transform: rotateY(180deg);
}

.discount-parent {
  position: relative;
}

.discount-style {
  position: absolute;
  top: -1px;
  left: 0px;
  background: linear-gradient(to right, rgba(184, 13, 214, 0.507), rgba(70, 6, 230, 0.838));
  padding: 10px;
  border-radius: 4px;
  /*border-bottom-right-radius: 100px;*/
}

.discount-style-2 {
  position: absolute;
  top: -1px;
  right: -13px;
  padding: 10px;
  border-radius: 4px;
  /*border-bottom-left-radius: 100px;*/
  width: 100px;
  background-color: orange;
  animation: slideDown 5s infinite;
}

@keyframes slideDown {
  0% {
    transform: translateY(-10px);
    background-color: white;
  }

  50% {
    transform: translateY(0px);
    background-color: orangered;
  }

  100% {
    transform: translateY(-10px)
  }
}

.holder {
  position: relative;
  width: 100%;
  height: 100%;
}

.frame {
  width: 100%;
  height: 100%;
}

.bar {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 60px;
}

.fs100 {
  font-size: 100px;
}

.showPopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: black;
  z-index: 9999;
}

.circle {
  border-radius: 100px;
  width: 40px;
  height: 40px;
  text-align: center;
}

.play-icon-position {
  position: absolute;
  top: -140px;
  left: 180px;
  right: 0;
  z-index: 999;
}

.play-icon-position a:hover {
  background-color: black;
  transition: 0.5s;
  border-radius: 0px;

}

.play-icon-position a {
  border-radius: 100px;
  width: 50px;
  height: 50px;
  font-size: 25px;
}

.img-circle {
  width: 50px;
  height: 50px;
  border-radius: 100px;
}

.font-weight-bold {
  font-weight: bold;
}

.opacity-80 {
  opacity: 0.8;
}

.bg-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.slideImage {
  height: 350px;
}

.dropdown-item:hover {
  background: linear-gradient(to right, rgba(184, 13, 214, 0.507), rgba(70, 6, 230, 0.838)) !important;
  color: white !important;
}


::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: #ccc;
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  background: #ccc;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background-color: blueviolet;
  border-radius: 20px;
}

.offer-text * {
  text-shadow: 1px 2px 3px #4449c6d4;
}

.enroll-link {
  background: linear-gradient(to right, rgba(184, 13, 214, 0.507), rgba(70, 6, 230, 0.838)) !important;
}

.enroll-link:hover {
  background: linear-gradient(to right, rgba(184, 13, 214, 0.507), rgba(70, 6, 230, 0.838)) !important;
  transition: 2s ease;
}

.text-yellow {
  color: yellow;
}

.popup-animation {
  animation: popupAnimation 0.5s ease-in-out;
}

.popup-animation-infinite {
  animation: popupAnimation 2s ease-in-out infinite;
}

.fade-animation-infinite {
  animation: fadeAnimation 2s ease-in-out infinite;
}

@keyframes fadeAnimation {
  from {
    opacity: 0.1;
  }

  to {
    opacity: 1;
  }
}

.progress-bar-course {
  animation: progressBar 2s ease-in-out;
}

@keyframes progressBar {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

.faq-question,
.review-question {
  padding: 10px;
  border-radius: 10px;
  border: 2px solid lightgray;
  transition: 0.5s;
}

.faq-question:hover {
  background-color: lightgray;
  padding: 10px;
  transition: 0.5s;
  border-radius: 10px;
}

.review-question:hover {
  background-color: black;
  color: white;
}

.faq-answer {
  padding: 10px;
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.w-30{
  width:30px;
  height:30px;
}

.cartCircle{
  width:20px;
  height:20px;
  border-radius:20px;
  position: absolute;
  right:1px;
  top:-10px;
}

.d-none{
  display: none;
}

.status-card {
  max-width: 400px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  background: white;
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.blinking-dot {
  width: 20px;
  height: 20px;
  background-color: red;
  border-radius: 50%;  /* Make it a circle */
  animation: blink 1s infinite; /* Blink every second */
}