.wmp-tool-button img{
  width:20px;
}
.embed-responsive-item{
  width:100%;
  height:560px;
}

.ytp-chrome-top-buttons{
  display: none!important;
}
