.landing-page-container{
  height:100vh;
  position: fixed;
  top:0px;
  left:0px;
  right:0px;
  z-index:999999999999;
}
.landing-video-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    filter: brightness(0.6);
  }

  .landing-container {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
  }

  .landing h1 {
    font-size: 3.5em;
    margin-bottom: 20px;
  }

  .landing p {
    font-size: 1.2em;
    margin-bottom: 40px;
    color: #dcdcdc;
  }

  .landing-explore-btn {
    padding: 15px 40px;
    font-size: 1.2em;
    color: #fff;
    background-color: #ff7e5f;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    text-transform: uppercase;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .landing-explore-btn:hover {
    transform: scale(1.1);
    box-shadow: 0 8px 20px rgba(255, 126, 95, 0.6);
  }

  .landing-explore-btn:active {
    transform: scale(0.95);
    box-shadow: 0 4px 10px rgba(255, 126, 95, 0.4);
  }