.quiz-popup {
  position: fixed;
  z-index: 9999 !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: white;
  border: 100px solid #f9844a;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  animation: popupAnimation 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.quiz-popup h2 {
  color: #f3722c;
  margin: 0 0 10px;
  text-align: center;
}

.quiz-popup p {
  color: #333;
  font-size: 16px;
  margin: 10px 0;
}

.quiz-popup button {
  background-color: #f3722c;
  color: white;
  border: none;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.quiz-popup button:hover {
  background-color: #1594d8;
}

.disabled {
  background-color: gray !important;
}

@keyframes popupAnimation {
  from {
    transform: scale(0.5);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

.input-option {
  padding: 10px;
  margin: 5px;
  border: 2px solid lightgray;
  border-radius: 10px;
  transition: color 0.5s ease, background-color 0.5s ease;
  width: 500px;
}

.input-option input {
  font-size: 25px;
}

.input-option:hover {
  color: white;
  background-color: #f9844a;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.input-option:active {
  box-shadow: 0px 40px 100px rgba(0, 0, 0, 0.2);
}

.circle {
  position: absolute;
  top: -145px;
  right:-80px;
  margin:auto;
  width: 200px;
  height: 200px;
  border-radius: 100%;
  border: 2px solid white;
  background: conic-gradient(#f9844a var(--progress, 0deg),
      #000 0deg);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: shakeAnimation 1s ease-in-out infinite;
}

/* TODO: fix close icon issue */
.close-quiz {
  position: absolute;
  animation: none;
  top: -50px;
  right: -50px;
  height: 100px;
  width: 100px;
}

.refresh-quiz{
  position: absolute;
  left:-40px;
  top:-40px;
  width: 100px;
  height: 100px;
  border-radius: 100%!important;
}

.xp-points{
  position: absolute;
  left:-40px;
  bottom:-40px;
  width: 100px;
  height: 100px;
  border-radius: 100%!important;
}

.close-quiz:hover {
  background: tomato;
  color: white;
}

@keyframes shakeAnimation {
  from {
    transform: scale(0.9);
  }

  to {
    transform: scale(1);
  }
}

.time-left {
  font-size: 60px;
  color: white;
}

.submit-btn {
  width: 200px;
}

.quiz-description {
  width: 50vw;
}

.time-out-quiz {
  font-size: 100px;
}

.progress-background{
  height:10px;
  background-color: #4a6df9ec;
  margin-left:20px;
  border-radius: 10px;
}

@media screen and (max-width:480px) {

  .input-option{
    width:300px;
  }
  .quiz-description {
    width: 80vw;
  }

  .quiz-popup {
    border: 20px solid #f9844a;
  }

  .quiz-popup h2 {
    font-size: 20px;
  }

  .circle {
    position: absolute;
    width: 100px;
    height: 100px;
    top: -60px;
    right: 0;
    left: 0;
    margin: 0 auto;
  }

  .refresh-quiz{
    position: absolute;
    left:-10px;
    top:-10px;
    width: 50px;
    height: 50px;
    border-radius: 100%!important;
  }

  .close-quiz {
    top: -10px;
    height: 50px;
    width: 50px;
    right:-15px;
    border-radius: 100%!important;
  }

  .time-left {
    font-size: 20px;
  }

  .quiz-popup p {
    font-size: 11px;
  }

  .correct-answer {
    font-size: 20px;
  }

  .time-out-quiz {
    font-size: 50px;
  }
}