.instructor{
  width:30px;
  height:30px;
  border-radius: 100px;
}
.instructor2{
  width:30px;
  height:30px;
  border-radius: 5px;
  margin:10px 0;
  box-shadow: 1px 5px 5px lightgray;
}
.course-icon{
  width:30px;
  height:30px;
}
.download-source:hover{
  background-color: orange!important;
  border:1px solid orange!important;
  color:white!important;
}
.timeframes{
  position: absolute;
  top:0px;
  right:0px;
  min-width: 250px;
}
.timeframes3{
  height:50px;
}
.infoicon{
  animation: bounceIn 1s;
}
.infoicon2{
  animation: fadeIn 0.5s;
}
.timeframes-height{
  height: 505px;
  overflow: auto;
  animation: bounceIn 1s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.relative{
  position: relative;
}