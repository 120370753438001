.accordion-container {
  width: 100%;
  max-width: 600px;
  margin: auto;
  font-family: Arial, sans-serif;
}

.accordion-item {
  border-bottom: 1px solid #ddd;
}

.accordion-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
  cursor: pointer;
  background-color: #e6f8f5;
  transition: background-color 0.3s;
}

.accordion-header:hover {
  background-color: #b4dfd4;
}

.accordion-date-time {
  font-size: 0.9em;
  color: #555;
  width:270px;
}

.accordion-title {
  flex-grow: 1;
  margin-left: 1em;
  font-weight: bold;
  color: #333;
}

.accordion-icon {
  font-size: 1.2em;
  color: #888;
}

.accordion-content {
  padding: 1em;
  background-color: #fafafa;
  color: #666;
  border-top: 1px solid #ddd;
}

.progress-bar{
  height:20px;
}

.big-size{
  font-size:100px;
}

.smooth-open{
  background-color: black;
  animation: popupAnimationCenter 0.5s ease-in-out;
}

@keyframes popupAnimationCenter {
  from {
    transform: scale(1.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}