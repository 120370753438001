/* Full-screen overlay with semi-transparent background */
.overlay-celebration {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Background opacity of 0.5 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it appears above other content */
}

/* Container for centering the image */
.container-celebration {
  text-align: center;
}
