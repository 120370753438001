@import url(https://fonts.googleapis.com/css?family=Lato:400,100,100italic,300,300italic,400italic,700italic,700,900italic,900);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,100,200,300,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,100,200,300,500,600,700,800,900);
body,* {
  margin: 0;
  font-family:"Century Gothic", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.mh-100{
  min-height: 100vh;
}
.course-image {
  width: 50%;
  height: 200px;
}

.course-image2 {
  height: 250px;
}

.srv-validation-message,
.error-message {
  color: orange;
}

.new-image {
  width: 100px;
  height: 50px;
  position: relative;
  top: -20px;
  right: -153px;
}

.new-image2 {
  width: 100px;
  height: 50px;
  position: relative;
  top: -20px;
  right: -124px;
}

.new-img {
  width: 50px;
  height: 50px;
  position: relative;
  top: 0px;
  right: 0px;
}

.cursor-pointer {
  cursor: pointer;
}

.custom-bg,
.btn-primary,
.bg-primary {
  /*background: linear-gradient(to right bottom,#6d327c,#485DA6,#00a1ba,#01b18e,#32b37b);*/
  background: linear-gradient(to right, rgba(13, 53, 214, 0.849), rgb(255, 0, 140));
  border: 0 !important;
}

.custom-bg2,
.bg-dark {
  background: linear-gradient(to right, rgba(22, 22, 22, 0.849), rgb(54, 54, 88));
}

.custom-bg3 {
  background: linear-gradient(to right, rgba(22, 22, 22, 0.849), rgb(31, 31, 189));
  transition: color 5s ease-in-out;
}

.custom-bg3:hover {
  background: linear-gradient(to right, rgba(184, 13, 214, 0.507), rgba(70, 6, 230, 0.838));
}

.h250 {
  min-height: 350px;
}

.fs-50 {
  font-size: 50px !important;
}

.fs-100 {
  font-size: 100px !important;
}

.li-hover:hover {
  background-color: lightgray;
  transition: 0.5s;
}

@media screen and (max-width:480px) {
  .typing-effect{
    animation: none!important;
  overflow: none!important;
  white-space: wrap!important;
  }
  .timeframes3 {
    height: 25px !important;
  }

  .timeframes {
    min-width: 100px !important;
  }

  .timeframes-height {
    max-height: 185px !important;
    overflow: auto;
  }

  .navbar-brand h2 {
    font-size: 20px !important;
  }

  .user-dropdown1 {
    width: 100% !important;
  }

  .navOption {
    margin: 5px;
  }

  .mbw-100 {
    width: 100%;
  }

  .new-image {
    left: 43%;
    transform: rotateZ(1deg);
  }

  .embed-responsive-item {
    height: 280px !important;
  }

  .slideImage {
    width: 100% !important;
    height: 250px !important;
  }

  .showPopup h3,
  .showPopup h1,
  .showPopup h4,
  .dashboard-heading {
    font-size: 18px;
  }

  .timer-container {
    display: none;
  }

  .popup {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw !important;
  }

  .close-btn {
    right: 10px !important;
    top: 10px !important;
  }

  .sticky-board {
    position: relative !important;
    top: 0 !important;
  }

  .d-reverse {
    display: flex !important;
    flex-direction: column-reverse !important;
  }

  .main-content {
    height: 800px !important;
  }

  .video-player-overlay{
    border:0px!important;
  }

}

.festival-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 999999;
  animation: fadeOut 5s 1 3s;
}

.festival-img {
  width: 100%;
  height: 100vh;
}

@keyframes fadeOut {
  0% {
    opacity: 1
  }

  50% {
    opacity: 0.8;
    background: linear-gradient(to right, rgba(184, 13, 214, 0.507), rgba(70, 6, 230, 0.838));
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  100% {
    opacity: 1
  }
}

.shadowAnim {
  animation: fadeOut 5s infinite;
}

/* The flip box container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-box {
  /*background-color: transparent;*/
  /*width: 300px;*/
  height: 200px;
  /*border: 1px solid #f1f1f1;*/
  perspective: 1000px;
}

/* This container is needed to position the front and back side */
.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-box:hover .flip-box-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-box-front,
.flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  /* Safari */
  backface-visibility: hidden;
}

/* Style the front side */
.flip-box-front {
  /*background-color: #bbb;*/
  color: black;
}

/* Style the back side */
.flip-box-back {
  /*background-color: dodgerblue;*/
  color: white;
  transform: rotateY(180deg);
}

.discount-parent {
  position: relative;
}

.discount-style {
  position: absolute;
  top: -1px;
  left: 0px;
  background: linear-gradient(to right, rgba(184, 13, 214, 0.507), rgba(70, 6, 230, 0.838));
  padding: 10px;
  border-radius: 4px;
  /*border-bottom-right-radius: 100px;*/
}

.discount-style-2 {
  position: absolute;
  top: -1px;
  right: -13px;
  padding: 10px;
  border-radius: 4px;
  /*border-bottom-left-radius: 100px;*/
  width: 100px;
  background-color: orange;
  animation: slideDown 5s infinite;
}

@keyframes slideDown {
  0% {
    transform: translateY(-10px);
    background-color: white;
  }

  50% {
    transform: translateY(0px);
    background-color: orangered;
  }

  100% {
    transform: translateY(-10px)
  }
}

.holder {
  position: relative;
  width: 100%;
  height: 100%;
}

.frame {
  width: 100%;
  height: 100%;
}

.bar {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 60px;
}

.fs100 {
  font-size: 100px;
}

.showPopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: black;
  z-index: 9999;
}

.circle {
  border-radius: 100px;
  width: 40px;
  height: 40px;
  text-align: center;
}

.play-icon-position {
  position: absolute;
  top: -140px;
  left: 180px;
  right: 0;
  z-index: 999;
}

.play-icon-position a:hover {
  background-color: black;
  transition: 0.5s;
  border-radius: 0px;

}

.play-icon-position a {
  border-radius: 100px;
  width: 50px;
  height: 50px;
  font-size: 25px;
}

.img-circle {
  width: 50px;
  height: 50px;
  border-radius: 100px;
}

.font-weight-bold {
  font-weight: bold;
}

.opacity-80 {
  opacity: 0.8;
}

.bg-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.slideImage {
  height: 350px;
}

.dropdown-item:hover {
  background: linear-gradient(to right, rgba(184, 13, 214, 0.507), rgba(70, 6, 230, 0.838)) !important;
  color: white !important;
}


::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: #ccc;
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  background: #ccc;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background-color: blueviolet;
  border-radius: 20px;
}

.offer-text * {
  text-shadow: 1px 2px 3px #4449c6d4;
}

.enroll-link {
  background: linear-gradient(to right, rgba(184, 13, 214, 0.507), rgba(70, 6, 230, 0.838)) !important;
}

.enroll-link:hover {
  background: linear-gradient(to right, rgba(184, 13, 214, 0.507), rgba(70, 6, 230, 0.838)) !important;
  transition: 2s ease;
}

.text-yellow {
  color: yellow;
}

.popup-animation {
  animation: popupAnimation 0.5s ease-in-out;
}

.popup-animation-infinite {
  animation: popupAnimation 2s ease-in-out infinite;
}

.fade-animation-infinite {
  animation: fadeAnimation 2s ease-in-out infinite;
}

@keyframes fadeAnimation {
  from {
    opacity: 0.1;
  }

  to {
    opacity: 1;
  }
}

.progress-bar-course {
  animation: progressBar 2s ease-in-out;
}

@keyframes progressBar {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

.faq-question,
.review-question {
  padding: 10px;
  border-radius: 10px;
  border: 2px solid lightgray;
  transition: 0.5s;
}

.faq-question:hover {
  background-color: lightgray;
  padding: 10px;
  transition: 0.5s;
  border-radius: 10px;
}

.review-question:hover {
  background-color: black;
  color: white;
}

.faq-answer {
  padding: 10px;
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.w-30{
  width:30px;
  height:30px;
}

.cartCircle{
  width:20px;
  height:20px;
  border-radius:20px;
  position: absolute;
  right:1px;
  top:-10px;
}

.d-none{
  display: none;
}

.status-card {
  max-width: 400px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  background: white;
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.blinking-dot {
  width: 20px;
  height: 20px;
  background-color: red;
  border-radius: 50%;  /* Make it a circle */
  animation: blink 1s infinite; /* Blink every second */
}
.animcolor{
  animation: changeColor 5s infinite;
}
@keyframes changeColor {
  0%{color:orange}
  30%{color:white}
  50%{color:lime}
  70%{color:white}
  90%{color:orange}
  100%{color:lime}
}
.animcolor2{
  animation: changeColor2 5s infinite;
}
@keyframes changeColor2 {
  0%{color:white}
  30%{color:orange;transform: translate(10px,0px);}
  50%{color:white}
  70%{color:tomato;transform: rotate(180deg);}
  90%{color:white}
  100%{color:orange}
}
.animcolor3{
  animation: changeColor3 5s infinite;
}
@keyframes changeColor3 {
  0%{color:white}
  30%{color:orange;transform: translate(-10px,0px);}
  50%{color:white}
  70%{color:tomato;transform: rotate(180deg);}
  90%{color:white}
  100%{color:orange}
}
body{
  background-image: url(/static/media/festivalbg.0457b8c2.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: black;
  background-size:100% 100%;
}
.course-image{
  border-radius:10px;
  border-bottom:5px solid white;
}
.course-image:hover{
  border:10px solid lightgray;
  transition:0.5s;
}
.tech-team:hover{
  padding:10px;
  border:10px solid lightgray;
  transition: 0.5s;
  animation:borderAnim 2s 1;
}
@keyframes borderAnim{
  0%{border:50px solid lightgray;}
  50%{border:10px solid white;}
  100%{border:10px solid lightgray;}
}

.typing-effect {
  overflow: hidden;
  white-space: nowrap;
  border-right: 3px solid; /* Optional cursor */
  animation: typing 3.5s steps(40, end) 1,
              blink-caret .75s step-end infinite;
  font-size: 2em; /* Adjust size as needed */
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% } /* Or a specific width if you want to control it */
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: orange; } /* Example cursor color */
}
/* Full-screen overlay with semi-transparent background */
.overlay-celebration {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Background opacity of 0.5 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it appears above other content */
}

/* Container for centering the image */
.container-celebration {
  text-align: center;
}

.course-item:hover{
  background-color: rgba(22, 39, 227, 0.532);
  border-radius: 10px;
  transition: 1s;
  cursor: pointer;
}
.course-category:hover{
  transform: scale(1.1);
  /*color:rgba(245, 127, 36, 0.933);*/
  font-weight: bold;
  transition: 0.5s;
}
@media screen and (max-width:480px) {
  .category{
    font-size:10px;
  }
  .course-category .display-6{
    font-size:20px;
  }
  .course-category:hover{
    transform: scale(1);
  }
}
.video-item{
  padding:5px 20px;
  cursor: pointer;
}
.video-item span{
  font-weight: bold;
}
.video-item:hover{
  background-color: #0d6efd;
  color:white;
  transition: 0.5s;
}
.selected-video{
  background-color: #0d6efd;
  color:white;
}
.course-icon{
  width:150px;
  height:50px;
}
.video-items2{
  height:530px;
  overflow: auto;
}
.video-item3{
  width:100%;
  height:524px;
  overflow: hidden;
}

.pl-2{
  padding-left:15px;
}
.video-item{
  padding:5px 10px;
  cursor: pointer;
}
.video-item span{
  font-weight: bold;
}
.video-item:hover{
  background-color: #0d6efd;
  color:white;
  transition: 0.5s;
}
.locked-lecture:hover{
  background-color: gray;
  cursor: not-allowed;
}
.selected-video{
  background-color: #0d6efd;
  color:white;
}
.course-icon{
  width:100px;
  height:50px;
}
.video-items{
  max-height:450px;
  overflow: auto;
}

.video-items2{
  height:530px;
  overflow: auto;
}
.video-item3{
  width:100%;
  height:524px;
  overflow: hidden;
}

.pl-2{
  padding-left:15px;
}
.course-thumbnail{
  width:100px;
}
.z-11{
  z-index: 10!important;
}
@media screen and (max-width:480px) { 
  .enrollOption *{
    font-size:11px;
  }
  .img-fluid{
    width:95px;
  }
}
.wmp-tool-button img{
  width:20px;
}
.embed-responsive-item{
  width:100%;
  height:560px;
}

.ytp-chrome-top-buttons{
  display: none!important;
}

.instructor{
  width:30px;
  height:30px;
  border-radius: 100px;
}
.instructor2{
  width:30px;
  height:30px;
  border-radius: 5px;
  margin:10px 0;
  box-shadow: 1px 5px 5px lightgray;
}
.course-icon{
  width:30px;
  height:30px;
}
.download-source:hover{
  background-color: orange!important;
  border:1px solid orange!important;
  color:white!important;
}
.timeframes{
  position: absolute;
  top:0px;
  right:0px;
  min-width: 250px;
}
.timeframes3{
  height:50px;
}
.infoicon{
  animation: bounceIn 1s;
}
.infoicon2{
  animation: fadeIn 0.5s;
}
.timeframes-height{
  height: 505px;
  overflow: auto;
  animation: bounceIn 1s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.relative{
  position: relative;
}
#generic_price_table {
    background-color: #000;
}

/*PRICE COLOR CODE START*/
#generic_price_table .generic_content {
    background-color: #fff;
}

#generic_price_table .generic_content .generic_head_price {
    background-color: #f6f6f6;
}

#generic_price_table .generic_content .generic_head_price .generic_head_content .head_bg {
    border-color: #e4e4e4 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #e4e4e4;
}

#generic_price_table .generic_content .generic_head_price .generic_head_content .head span {
    color: #525252;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price .sign {
    color: #414141;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price .currency {
    color: #414141;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price .cent {
    color: #414141;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .month {
    color: #414141;
}

#generic_price_table .generic_content .generic_feature_list ul li {
    color: #a7a7a7;
}

#generic_price_table .generic_content .generic_feature_list ul li span {
    color: #414141;
}

#generic_price_table .generic_content .generic_feature_list ul li:hover {
    background-color: #E4E4E4;
    border-left: 5px solid #2ECC71;
}

#generic_price_table .generic_content .generic_price_btn a {
    border: 1px solid #2ECC71;
    color: #2ECC71;
}

#generic_price_table .generic_content.active .generic_head_price .generic_head_content .head_bg,
#generic_price_table .generic_content:hover .generic_head_price .generic_head_content .head_bg {
    border-color: #2ECC71 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #2ECC71;
    color: #fff;
}

#generic_price_table .generic_content:hover .generic_head_price .generic_head_content .head span,
#generic_price_table .generic_content.active .generic_head_price .generic_head_content .head span {
    color: #fff;
}

#generic_price_table .generic_content:hover .generic_price_btn a,
#generic_price_table .generic_content.active .generic_price_btn a {
    background-color: #2ECC71;
    color: #fff;
}

#generic_price_table {
    margin: 0px 0 0px 0;
    font-family: 'Raleway', sans-serif;
}

.row .table {
    padding: 28px 0;
}

/*PRICE BODY CODE START*/

#generic_price_table .generic_content {
    overflow: hidden;
    position: relative;
    text-align: center;
}

#generic_price_table .generic_content .generic_head_price {
    margin: 0 0 20px 0;
}

#generic_price_table .generic_content .generic_head_price .generic_head_content {
    margin: 0 0 50px 0;
}

#generic_price_table .generic_content .generic_head_price .generic_head_content .head_bg {
    border-style: solid;
    border-width: 90px 1411px 23px 399px;
    position: absolute;
}

#generic_price_table .generic_content .generic_head_price .generic_head_content .head {
    padding-top: 40px;
    position: relative;
    z-index: 1;
}

#generic_price_table .generic_content .generic_head_price .generic_head_content .head span {
    font-family: "Raleway", sans-serif;
    font-size: 26px;
    font-weight: 400;
    letter-spacing: 2px;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag {
    padding: 0 0 20px;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price {
    display: block;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price .sign {
    display: inline-block;
    font-family: "Lato", sans-serif;
    font-size: 28px;
    font-weight: 400;
    vertical-align: middle;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price .currency {
    font-family: "Lato", sans-serif;
    font-size: 60px;
    font-weight: 300;
    letter-spacing: -2px;
    line-height: 60px;
    padding: 0;
    vertical-align: middle;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price .cent {
    display: inline-block;
    font-family: "Lato", sans-serif;
    font-size: 24px;
    font-weight: 400;
    vertical-align: bottom;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .month {
    font-family: "Lato", sans-serif;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 3px;
    vertical-align: bottom;
}

#generic_price_table .generic_content .generic_feature_list ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

#generic_price_table .generic_content .generic_feature_list ul li {
    font-family: "Lato", sans-serif;
    font-size: 18px;
    padding: 15px 0;
    transition: all 0.3s ease-in-out 0s;
}

#generic_price_table .generic_content .generic_feature_list ul li:hover {
    transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -ms-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    -webkit-transition: all 0.3s ease-in-out 0s;

}

#generic_price_table .generic_content .generic_feature_list ul li .fa {
    padding: 0 10px;
}

#generic_price_table .generic_content .generic_price_btn {
    background-color: white;
    margin: 20px 0 32px;
}

#generic_price_table .generic_content .generic_price_btn a {
    border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    -webkit-border-radius: 50px;
    display: inline-block;
    font-family: "Lato", sans-serif;
    font-size: 18px;
    outline: medium none;
    padding: 12px 30px;
    text-decoration: none;
    text-transform: uppercase;
}

#generic_price_table .generic_content,
#generic_price_table .generic_content:hover,
#generic_price_table .generic_content .generic_head_price .generic_head_content .head_bg,
#generic_price_table .generic_content:hover .generic_head_price .generic_head_content .head_bg,
#generic_price_table .generic_content .generic_head_price .generic_head_content .head h2,
#generic_price_table .generic_content:hover .generic_head_price .generic_head_content .head h2,
#generic_price_table .generic_content .price,
#generic_price_table .generic_content:hover .price,
#generic_price_table .generic_content .generic_price_btn a,
#generic_price_table .generic_content:hover .generic_price_btn a {
    transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -ms-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    -webkit-transition: all 0.3s ease-in-out 0s;
}

@media (max-width: 320px) {}

@media (max-width: 767px) {
    #generic_price_table .generic_content {
        margin-bottom: 75px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    #generic_price_table .col-md-3 {
        float: left;
        width: 50%;
    }

    #generic_price_table .col-md-4 {
        float: left;
        width: 50%;
    }

    #generic_price_table .generic_content {
        margin-bottom: 75px;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {}

@media (min-width: 1200px) {}

#generic_price_table_home {
    font-family: 'Raleway', sans-serif;
}

/*.text-center h1,
.text-center h1 a {
    color: #7885CB;
    font-size: 30px;
    font-weight: 300;
    text-decoration: none;
}*/

.demo-pic {
    margin: 0 auto;
}

.demo-pic:hover {
    opacity: 0.7;
}

#generic_price_table_home ul {
    margin: 0 auto;
    padding: 0;
    list-style: none;
    display: table;
}

#generic_price_table_home li {
    float: left;
}

#generic_price_table_home li+li {
    margin-left: 10px;
    padding-bottom: 10px;
}

#generic_price_table_home li a {
    display: block;
    width: 50px;
    height: 50px;
    font-size: 0px;
}

#generic_price_table_home .blue {
    background: #3498DB;
    transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .emerald {
    background: #2ECC71;
    transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .grey {
    background: #7F8C8D;
    transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .midnight {
    background: #34495E;
    transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .orange {
    background: #E67E22;
    transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .purple {
    background: #9B59B6;
    transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .red {
    background: #E74C3C;
    transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .turquoise {
    background: #1ABC9C;
    transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .blue:hover,
#generic_price_table_home .emerald:hover,
#generic_price_table_home .grey:hover,
#generic_price_table_home .midnight:hover,
#generic_price_table_home .orange:hover,
#generic_price_table_home .purple:hover,
#generic_price_table_home .red:hover,
#generic_price_table_home .turquoise:hover {
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .divider {
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
    padding: 20px;
}

#generic_price_table_home .divider span {
    width: 100%;
    display: table;
    height: 2px;
    background: #ddd;
    margin: 50px auto;
    line-height: 2px;
}

#generic_price_table_home .itemname {
    text-align: center;
    font-size: 50px;
    padding: 50px 0 20px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 40px;
    text-decoration: none;
    font-weight: 300;
}

#generic_price_table_home .itemnametext {
    text-align: center;
    font-size: 20px;
    padding-top: 5px;
    text-transform: uppercase;
    display: inline-block;
}

#generic_price_table_home .footer {
    padding: 40px 0;
}

.price-heading {
    text-align: center;
}

.price-heading h1 {
    /*color: rgb(196, 108, 31);*/
    margin: 0;
    padding: 0 0 50px 0;
}

.demo-button {
    background-color: #333333;
    color: #ffffff;
    display: table;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 50px;
    outline-color: -moz-use-text-color;
    outline-style: none;
    outline-width: medium;
    padding: 10px;
    text-align: center;
    text-transform: uppercase;
}

.bottom_btn {
    background-color: #333333;
    color: #ffffff;
    display: table;
    font-size: 28px;
    margin: 60px auto 20px;
    padding: 10px 25px;
    text-align: center;
    text-transform: uppercase;
}

.demo-button:hover {
    background-color: #666;
    color: #FFF;
    text-decoration: none;

}

.bottom_btn:hover {
    background-color: #666;
    color: #FFF;
    text-decoration: none;
}

ol label{
  cursor: pointer;
}
.vm-side-menu{
  box-shadow: 0px 0px 10px 1px lightgray;
  background-color: black;
}

.vm-heading,.vm-heading-icon{
  color:#58CC02;
}

.vm-menu-option{
  color:#777777;
  font-weight: bold;
  text-align: left;
  width:120px;
}

.learning-nav-items .nav-link{
  width:200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.learning-nav-items p{
  margin:0;
  padding:0;
  box-sizing: border-box;
}

.learning-nav-items .nav-item{
  margin:5px;
  border-radius: 10px;
  padding:5px;
}

.learning-nav-items .nav-item:hover{
  background-color: #DDF4FF;
}

.learning-nav-items .nav-item:hover .vm-menu-option{
  color:#1CB0F6;
}
.accordion-container {
  width: 100%;
  max-width: 600px;
  margin: auto;
  font-family: Arial, sans-serif;
}

.accordion-item {
  border-bottom: 1px solid #ddd;
}

.accordion-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
  cursor: pointer;
  background-color: #e6f8f5;
  transition: background-color 0.3s;
}

.accordion-header:hover {
  background-color: #b4dfd4;
}

.accordion-date-time {
  font-size: 0.9em;
  color: #555;
  width:270px;
}

.accordion-title {
  flex-grow: 1;
  margin-left: 1em;
  font-weight: bold;
  color: #333;
}

.accordion-icon {
  font-size: 1.2em;
  color: #888;
}

.accordion-content {
  padding: 1em;
  background-color: #fafafa;
  color: #666;
  border-top: 1px solid #ddd;
}

.progress-bar{
  height:20px;
}

.big-size{
  font-size:100px;
}

.smooth-open{
  background-color: black;
  animation: popupAnimationCenter 0.5s ease-in-out;
}

@keyframes popupAnimationCenter {
  from {
    transform: scale(1.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
.live-symbol {
  display: flex;
  align-items: center;
  justify-content: center;
}

.live-dot {
  width: 50px;
  height: 50px;
  background-color: red;
  border-radius: 50%;
  margin-right: 0.5em;
  animation: blink 1s infinite;
}

@keyframes blink {
  0%, 50%, 100% { opacity: 1; }
  25%, 75% { opacity: 0; }
}

.live-text {
  font-size: 3.5em;
  font-weight: bold;
  color: #e74c3c;
}

.countdown-timer {
  font-size: 2.5em;
  color: white;
  margin: 0.5em 0;
}

.join-live-btn {
  padding: 0.75em 1.5em;
  font-size: 1em;
  color: #000;
  background-color: white;
  border:5px solid white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.5s,color 0.5s,border 0.5s;
}

.join-live-btn:hover {
  background-color: #000;
  color:white;
  border:5px solid #c302b0ec;
}

.upcoming-topics {
  margin-top: 1em;
  text-align: left;
}

.upcoming-topics h4 {
  font-size: 1.2em;
  margin-bottom: 0.5em;
  color: #333;
}

.upcoming-topics ul {
  list-style-type: none;
  padding: 0;
}

.upcoming-topics li {
  font-size: 0.9em;
  color: #666;
}

.section-1{
  height:auto;
  overflow: auto;
}

.bg-black{
  background-color: black;
}

.live-section{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height:320px;
}
.quiz-popup {
  position: fixed;
  z-index: 9999 !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: white;
  border: 100px solid #f9844a;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  animation: popupAnimation 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.quiz-popup h2 {
  color: #f3722c;
  margin: 0 0 10px;
  text-align: center;
}

.quiz-popup p {
  color: #333;
  font-size: 16px;
  margin: 10px 0;
}

.quiz-popup button {
  background-color: #f3722c;
  color: white;
  border: none;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.quiz-popup button:hover {
  background-color: #1594d8;
}

.disabled {
  background-color: gray !important;
}

@keyframes popupAnimation {
  from {
    transform: scale(0.5);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

.input-option {
  padding: 10px;
  margin: 5px;
  border: 2px solid lightgray;
  border-radius: 10px;
  transition: color 0.5s ease, background-color 0.5s ease;
  width: 500px;
}

.input-option input {
  font-size: 25px;
}

.input-option:hover {
  color: white;
  background-color: #f9844a;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.input-option:active {
  box-shadow: 0px 40px 100px rgba(0, 0, 0, 0.2);
}

.circle {
  position: absolute;
  top: -145px;
  right:-80px;
  margin:auto;
  width: 200px;
  height: 200px;
  border-radius: 100%;
  border: 2px solid white;
  background: conic-gradient(#f9844a 0deg,
      #000 0deg);
  background: conic-gradient(#f9844a var(--progress, 0deg),
      #000 0deg);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: shakeAnimation 1s ease-in-out infinite;
}

/* TODO: fix close icon issue */
.close-quiz {
  position: absolute;
  animation: none;
  top: -50px;
  right: -50px;
  height: 100px;
  width: 100px;
}

.refresh-quiz{
  position: absolute;
  left:-40px;
  top:-40px;
  width: 100px;
  height: 100px;
  border-radius: 100%!important;
}

.xp-points{
  position: absolute;
  left:-40px;
  bottom:-40px;
  width: 100px;
  height: 100px;
  border-radius: 100%!important;
}

.close-quiz:hover {
  background: tomato;
  color: white;
}

@keyframes shakeAnimation {
  from {
    transform: scale(0.9);
  }

  to {
    transform: scale(1);
  }
}

.time-left {
  font-size: 60px;
  color: white;
}

.submit-btn {
  width: 200px;
}

.quiz-description {
  width: 50vw;
}

.time-out-quiz {
  font-size: 100px;
}

.progress-background{
  height:10px;
  background-color: #4a6df9ec;
  margin-left:20px;
  border-radius: 10px;
}

@media screen and (max-width:480px) {

  .input-option{
    width:300px;
  }
  .quiz-description {
    width: 80vw;
  }

  .quiz-popup {
    border: 20px solid #f9844a;
  }

  .quiz-popup h2 {
    font-size: 20px;
  }

  .circle {
    position: absolute;
    width: 100px;
    height: 100px;
    top: -60px;
    right: 0;
    left: 0;
    margin: 0 auto;
  }

  .refresh-quiz{
    position: absolute;
    left:-10px;
    top:-10px;
    width: 50px;
    height: 50px;
    border-radius: 100%!important;
  }

  .close-quiz {
    top: -10px;
    height: 50px;
    width: 50px;
    right:-15px;
    border-radius: 100%!important;
  }

  .time-left {
    font-size: 20px;
  }

  .quiz-popup p {
    font-size: 11px;
  }

  .correct-answer {
    font-size: 20px;
  }

  .time-out-quiz {
    font-size: 50px;
  }
}
.bg-teal{
  background: linear-gradient(to bottom, #00303f, #c302b0ec);
  color:#00303f;
}

.bg-leaderboard{
  background: linear-gradient(to right, #00303f, #c302b0ec);
  color:white;
}

.table-responsive *{
  color:white;
}

.table-responsive tr:hover *{
  background-color: #e6f8f5;
  color:black;
  transition: 0.5s;
}

.table-responsive tr:nth-child(1) *{
  background-color: #e6f8f5;
  color:black;
  transition: 0.5s;
}

.main-content {
  height: 2000px;
}

.sticky-board{
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
}

.interaction-btn{
  border-radius: 100px!important;
}

.interaction-btn:hover{
  background-color: #e6f8f5!important;
  color:black!important;
}

.video-player-overlay{
  position: fixed;
  top:0;
  left:0;
  width:100%;
  height:100vh;
  background-color: black;
  z-index:9999;
  display: flex;
  justify-content: center;
  align-items: center;
  border:50px solid white;
}

.video-player-overlay iframe{
  height:100%;
  border-radius: 10px!important;
}

.close-btn{
  width:50px;
  height:50px;
  border:none;
  position: fixed;
  top:0;
  right:0;
  z-index: 9;
  border-radius: 100px;
  background-color: tomato;
  color:white;
}

.close-btn:hover{
  background-color: red;
}
.diwali-celebration {
    background-color: #f5f5f5;
    padding: 20px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    background-image: url("https://srichants.in/wp-content/uploads/2024/11/Happy-Sankranti-1024x585.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height:100vh;
    position: fixed;
    top:0px;
    left:0px;
    right:0px;
    z-index:999999999999;
}

.heading-diwali-celebration{
    font-size: 60px;
}
.heading-diwali{
    text-shadow: 2px 2px #fff;
    font-size:60px;
    font-weight: bold;
    background: -webkit-linear-gradient(left, #f00, #0f0, #00f, #f0f, #ff0, #f0f, #0ff, #0f0, #ff0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.diwali-celebration p{
    text-shadow: 2px 0px green;
    text-transform: capitalize;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.bottom-right-image {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 200px;
    height: 200px;
    transform: rotateY(180deg);
    animation: sideLeftAnimation 0.5s ease-in-out;
}


@keyframes sideLeftAnimation {
    from{
        opacity:0;
        transform: translateX(100px);
    }
    to{
        opacity:1;
        transform: translateX(0px);
    }
}

.bottom-left-image{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 200px;
    height: 200px;
    animation: sideLeftAnimation 0.5s ease-in-out;
}

.continue-btn{
    position: absolute;
    bottom: 200px;
    right:0;
    left:0;
    margin:auto;
    width:100px;
    height:100px;
    font-size:20px;
    background: linear-gradient(to right, #f00, rgb(38, 0, 255));
    border-radius: 100px;
    border:none;
    box-shadow: 0px 0px 10px #000;
    color:white;
    font-weight: bold;
    animation: pulse 2s 1;
    transition: 2s;
    font-size:50px;
}

.continue-btn:hover{
    box-shadow: 0px 0px 100px #000;
    transition: 2s;
    transform: scale(1.5);
}

@keyframes pulse {
    from {
        box-shadow: 0px 0px 10px #000;
    }
    to {
        box-shadow: 0px 0px 100px #000;
        transform: scale(1.5);
    }
}

.vm-character-right{
    position: absolute;
    bottom:0;
    right:0;
}

.vm-character-left{
    position: absolute;
    bottom:0;
    left:0;
    transform: rotateY(180deg);
}


  .btn-wrapper::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    padding: 10px;
    background: conic-gradient(#4CAF50 0deg, transparent 90deg, #4CAF50 180deg, transparent 270deg, #4CAF50 360deg);
    z-index: 0;
    animation: rotateBorder 2s linear infinite;
    mask: linear-gradient(#000 0 0) content-box, linear-gradient(#000 0 0);
    -webkit-mask: linear-gradient(#000 0 0) content-box, linear-gradient(#000 0 0);
    mask-composite: exclude;
    -webkit-mask-composite: destination-out;
  }

  @keyframes rotateBorder {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
.vm-side-menu{
  box-shadow: 0px 0px 10px 1px lightgray;
  background-color: black;
}

.vm-heading,.vm-heading-icon{
  color:#58CC02;
}

.vm-menu-option{
  color:#777777;
  font-weight: bold;
  text-align: left;
  width:120px;
}

.learning-nav-items .nav-link{
  width:200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.learning-nav-items p{
  margin:0;
  padding:0;
  box-sizing: border-box;
}

.learning-nav-items .nav-item{
  margin:5px;
  border-radius: 10px;
  padding:5px;
}

.learning-nav-items .nav-item:hover{
  background-color: #DDF4FF;
}

.learning-nav-items .nav-item:hover .vm-menu-option{
  color:#1CB0F6;
}
.vm-side-menu{
  box-shadow: 0px 0px 10px 1px lightgray;
  background-color: black;
}

.vm-heading,.vm-heading-icon{
  color:#58CC02;
}

.vm-menu-option{
  color:#777777;
  font-weight: bold;
  text-align: left;
  width:120px;
}

.learning-nav-items .nav-link{
  width:200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.learning-nav-items p{
  margin:0;
  padding:0;
  box-sizing: border-box;
}

.learning-nav-items .nav-item{
  margin:5px;
  border-radius: 10px;
  padding:5px;
}

.learning-nav-items .nav-item:hover{
  background-color: #DDF4FF;
}

.learning-nav-items .nav-item:hover .vm-menu-option{
  color:#1CB0F6;
}
.landing-page-container{
  height:100vh;
  position: fixed;
  top:0px;
  left:0px;
  right:0px;
  z-index:999999999999;
}
.landing-video-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    -webkit-filter: brightness(0.6);
            filter: brightness(0.6);
  }

  .landing-container {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
  }

  .landing h1 {
    font-size: 3.5em;
    margin-bottom: 20px;
  }

  .landing p {
    font-size: 1.2em;
    margin-bottom: 40px;
    color: #dcdcdc;
  }

  .landing-explore-btn {
    padding: 15px 40px;
    font-size: 1.2em;
    color: #fff;
    background-color: #ff7e5f;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    text-transform: uppercase;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .landing-explore-btn:hover {
    transform: scale(1.1);
    box-shadow: 0 8px 20px rgba(255, 126, 95, 0.6);
  }

  .landing-explore-btn:active {
    transform: scale(0.95);
    box-shadow: 0 4px 10px rgba(255, 126, 95, 0.4);
  }
