.live-symbol {
  display: flex;
  align-items: center;
  justify-content: center;
}

.live-dot {
  width: 50px;
  height: 50px;
  background-color: red;
  border-radius: 50%;
  margin-right: 0.5em;
  animation: blink 1s infinite;
}

@keyframes blink {
  0%, 50%, 100% { opacity: 1; }
  25%, 75% { opacity: 0; }
}

.live-text {
  font-size: 3.5em;
  font-weight: bold;
  color: #e74c3c;
}

.countdown-timer {
  font-size: 2.5em;
  color: white;
  margin: 0.5em 0;
}

.join-live-btn {
  padding: 0.75em 1.5em;
  font-size: 1em;
  color: #000;
  background-color: white;
  border:5px solid white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.5s,color 0.5s,border 0.5s;
}

.join-live-btn:hover {
  background-color: #000;
  color:white;
  border:5px solid #c302b0ec;
}

.upcoming-topics {
  margin-top: 1em;
  text-align: left;
}

.upcoming-topics h4 {
  font-size: 1.2em;
  margin-bottom: 0.5em;
  color: #333;
}

.upcoming-topics ul {
  list-style-type: none;
  padding: 0;
}

.upcoming-topics li {
  font-size: 0.9em;
  color: #666;
}

.section-1{
  height:auto;
  overflow: auto;
}

.bg-black{
  background-color: black;
}

.live-section{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height:320px;
}