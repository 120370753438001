.course-item:hover{
  background-color: rgba(22, 39, 227, 0.532);
  border-radius: 10px;
  transition: 1s;
  cursor: pointer;
}
.course-category:hover{
  transform: scale(1.1);
  /*color:rgba(245, 127, 36, 0.933);*/
  font-weight: bold;
  transition: 0.5s;
}
@media screen and (max-width:480px) {
  .category{
    font-size:10px;
  }
  .course-category .display-6{
    font-size:20px;
  }
  .course-category:hover{
    transform: scale(1);
  }
}