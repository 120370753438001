.bg-teal{
  background: linear-gradient(to bottom, #00303f, #c302b0ec);
  color:#00303f;
}

.bg-leaderboard{
  background: linear-gradient(to right, #00303f, #c302b0ec);
  color:white;
}

.table-responsive *{
  color:white;
}

.table-responsive tr:hover *{
  background-color: #e6f8f5;
  color:black;
  transition: 0.5s;
}

.table-responsive tr:nth-child(1) *{
  background-color: #e6f8f5;
  color:black;
  transition: 0.5s;
}

.main-content {
  height: 2000px;
}

.sticky-board{
  position: sticky;
  top: 80px;
}

.interaction-btn{
  border-radius: 100px!important;
}

.interaction-btn:hover{
  background-color: #e6f8f5!important;
  color:black!important;
}

.video-player-overlay{
  position: fixed;
  top:0;
  left:0;
  width:100%;
  height:100vh;
  background-color: black;
  z-index:9999;
  display: flex;
  justify-content: center;
  align-items: center;
  border:50px solid white;
}

.video-player-overlay iframe{
  height:100%;
  border-radius: 10px!important;
}

.close-btn{
  width:50px;
  height:50px;
  border:none;
  position: fixed;
  top:0;
  right:0;
  z-index: 9;
  border-radius: 100px;
  background-color: tomato;
  color:white;
}

.close-btn:hover{
  background-color: red;
}