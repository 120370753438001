.vm-side-menu{
  box-shadow: 0px 0px 10px 1px lightgray;
  background-color: black;
}

.vm-heading,.vm-heading-icon{
  color:#58CC02;
}

.vm-menu-option{
  color:#777777;
  font-weight: bold;
  text-align: left;
  width:120px;
}

.learning-nav-items .nav-link{
  width:200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.learning-nav-items p{
  margin:0;
  padding:0;
  box-sizing: border-box;
}

.learning-nav-items .nav-item{
  margin:5px;
  border-radius: 10px;
  padding:5px;
}

.learning-nav-items .nav-item:hover{
  background-color: #DDF4FF;
}

.learning-nav-items .nav-item:hover .vm-menu-option{
  color:#1CB0F6;
}