.animcolor{
  animation: changeColor 5s infinite;
}
@keyframes changeColor {
  0%{color:orange}
  30%{color:white}
  50%{color:lime}
  70%{color:white}
  90%{color:orange}
  100%{color:lime}
}
.animcolor2{
  animation: changeColor2 5s infinite;
}
@keyframes changeColor2 {
  0%{color:white}
  30%{color:orange;transform: translate(10px,0px);}
  50%{color:white}
  70%{color:tomato;transform: rotate(180deg);}
  90%{color:white}
  100%{color:orange}
}
.animcolor3{
  animation: changeColor3 5s infinite;
}
@keyframes changeColor3 {
  0%{color:white}
  30%{color:orange;transform: translate(-10px,0px);}
  50%{color:white}
  70%{color:tomato;transform: rotate(180deg);}
  90%{color:white}
  100%{color:orange}
}
body{
  background-image: url(./festivalbg.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: black;
  background-size:100% 100%;
}
.course-image{
  border-radius:10px;
  border-bottom:5px solid white;
}
.course-image:hover{
  border:10px solid lightgray;
  transition:0.5s;
}
.tech-team:hover{
  padding:10px;
  border:10px solid lightgray;
  transition: 0.5s;
  animation:borderAnim 2s 1;
}
@keyframes borderAnim{
  0%{border:50px solid lightgray;}
  50%{border:10px solid white;}
  100%{border:10px solid lightgray;}
}

.typing-effect {
  overflow: hidden;
  white-space: nowrap;
  border-right: 3px solid; /* Optional cursor */
  animation: typing 3.5s steps(40, end) 1,
              blink-caret .75s step-end infinite;
  font-size: 2em; /* Adjust size as needed */
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% } /* Or a specific width if you want to control it */
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: orange; } /* Example cursor color */
}